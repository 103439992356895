/* eslint-disable no-nested-ternary */
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import Link from '@/components/atoms/Link';
import Icon from '@/components/atoms/Icon';
import useDeviceType from '@/hooks/useDeviceType';

const storeId = process.env.NEXT_PUBLIC_STORE_ID;

const Pagination = ({ pagination }) => {
  const { isDesktop } = useDeviceType();
  const router = useRouter();

  const newPageUrl = (page) => {
    if (page === 0 || page > pagination.total) return '';
    return router.asPath.replace(/\/p\/([^&]*)/, `/p/${page}`);
  };

  const renderPages = () => {
    const pagesItems = [];

    let finalPage;

    if (pagination.current >= 3) {
      if (pagination.current + 2 <= pagination.total)
        finalPage = pagination.current + 1;
      else finalPage = pagination.total;
    } else if (!isDesktop && pagination.total >= 3) finalPage = 3;
    else if (isDesktop && pagination.total >= 5) finalPage = 5;
    else finalPage = pagination.total;

    const pages = {
      init: pagination.current === 1 ? 1 : pagination.current - 1,
      final: finalPage
    };

    const border = (i) =>
      storeId === 'city'
        ? `3px solid ${
            i === pagination.current
              ? 'var(--color-primary)'
              : 'var(--color-white)'
          }`
        : `${i === pagination.current ? '1px solid var(--color-primary)' : ''}`;

    // eslint-disable-next-line no-plusplus
    for (let i = pages.init; i <= pages.final; i++) {
      pagesItems.push(
        <li key={i}>
          <Link
            absolutePath
            href={newPageUrl(i)}
            linkStyle={{
              cursor: 'pointer',
              padding: storeId === 'city' ? '15px' : '15px 25px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '8px',
              border: border(i),
              color:
                i === pagination.current ? 'var(--color-primary)' : 'white',
              borderWidth: i === pagination.current ? '2px' : '1.5px',
              fontWeight:
                storeId === 'city' && i === pagination.current && '600',
              height: '2.5rem',
              width: '2.5rem'
            }}
          >
            {i}
          </Link>
        </li>
      );
    }
    return pagesItems;
  };
  return (
    <div className={styles.container}>
      <ul className={styles.pages}>
        <li className={`${styles.page} ${styles.chevron}`}>
          <Link
            absolutePath
            href={newPageUrl(pagination.current - 1)}
            variant={`${
              pagination.current <= 1 ? 'transparent-disabled' : 'transparent'
            }`}
            style={{
              pointerEvents: pagination.current <= 1 ? 'none' : 'auto'
            }}
          >
            <Icon
              type="arrow"
              width="13px"
              reverse
              color={`${
                pagination.current <= 1
                  ? 'var(--color-text-on-transparent-disabled)'
                  : 'var(--color-text-on-transparent)'
              }`}
            />
          </Link>
        </li>
        {renderPages()}

        <li className={`${styles.page} ${styles.chevron}`}>
          <Link
            absolutePath
            href={newPageUrl(pagination.current + 1, true)}
            style={{
              pointerEvents:
                pagination.current >= pagination.total ? 'none' : 'auto'
            }}
          >
            <Icon
              type="arrow"
              width="13px"
              color={`${
                pagination.current >= pagination.total
                  ? 'var(--color-text-on-transparent-disabled)'
                  : 'var(--color-text-on-transparent)'
              }`}
            />
          </Link>
        </li>
      </ul>
    </div>
  );
};
Pagination.propTypes = {
  pagination: PropTypes.object.isRequired
};
export default Pagination;
